import { Image } from "@chakra-ui/image";
import {
  Box,
  Center,
  Heading,
  Link as ChakraLink,
  Text,
  VStack,
} from "@chakra-ui/layout";
import { PortableText } from "@portabletext/react";
import imageUrlBuilder from "@sanity/image-url";
import Link from "next/link";
import React from "react";
import { formatDate } from "src/hooks/formatDate";

import client from "../../client";

function urlFor(source) {
  return imageUrlBuilder(client).image(source);
}

const NewsPreview = ({ news }) => {
  return (
    <Center>
      <ChakraLink as={Link} href={`/nyheter/${news.slug.current}`}>
        <a>
          <Box maxW={"320px"} minW={"160px"}>
            {news.mainImage && <Image src={urlFor(news.mainImage).url()} />}
            <Heading size={"sm"} pt={2}>
              {news.title}
            </Heading>
            <Text>{formatDate({ date: news.publishedAt })}</Text>
          </Box>
        </a>
      </ChakraLink>
    </Center>
  );
};

export default NewsPreview;
