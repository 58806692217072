import { Icon } from "@chakra-ui/react";
import React from "react";

const MenuIcon = () => {
  return (
    <Icon viewBox="0 0 33 22">
      <path
        id="Icon_material-menu"
        data-name="Icon material-menu"
        d="M4.5,31h33V27.333H4.5Zm0-9.167h33V18.167H4.5ZM4.5,9v3.667h33V9Z"
        transform="translate(-4.5 -9)"
      />
    </Icon>
  );
};

export default MenuIcon;
