import {
  Box,
  Flex,
  Heading,
  Link as ChakraLink,
  Stack,
  Text,
} from "@chakra-ui/layout";
import Link from "next/link";

const Footer = () => {
  return (
    <footer>
      <Flex py={130}>
        <Stack
          direction={["column", "row"]}
          justify={"space-around"}
          align={"center"}
          w={"100%"}
          spacing="24px"
        >
          <Box w={350}>
            <Flex>
              <Text
                fontSize="2xl"
                color={"baseLight"}
                fontFamily="gotham-black"
              >
                RENT&nbsp;
              </Text>
              <Text fontSize="2xl" color={"baseLight"} fontFamily="gotham-book">
                SENTER
              </Text>
            </Flex>
            <Text>
              Rent Senter er et forebyggende antidopingprogram med fokus på
              kompetanseheving, informasjon og mulighet for dopingkontroll. Rent
              Senter-programmet er utviklet av Antidoping Norge.
            </Text>
          </Box>
          <Box w={350}>
            <Flex>
              <Text
                fontSize="2xl"
                color={"baseLight"}
                fontFamily="gotham-black"
              >
                ANTIDOPING
              </Text>
              <Text fontSize="2xl" color={"baseLight"} fontFamily="gotham-book">
                NORGE
              </Text>
            </Flex>
            <Box>
              <Text>Kontakt oss</Text>
              <Text>Tlf. 09765</Text>
              <Text>post@rentsenter.no</Text>
              <Text>post@antidoping.no</Text>
              <Text>Org. nr: 985 833 362 MVA</Text>
            </Box>
          </Box>
        </Stack>
      </Flex>
      <Flex bg={"baseLight"} py={31} color={"baseDark"}>
        <Stack
          direction={["column", "row"]}
          justify={"space-around"}
          align={"center"}
          w={"100%"}
          spacing="24px"
        >
          <Text fontSize={"sm"}>© 2020 Rent Senter. All Rights Reserved.</Text>
          <Box>
            <Stack
              direction={["column", "row"]}
              justify={"space-around"}
              align={"center"}
              w={"100%"}
              spacing="50px"
            >
              <ChakraLink as={Link} href="/">
                Personvern og informasjonskapsler
              </ChakraLink>
              <ChakraLink as={Link} href="/">
                Vilkår
              </ChakraLink>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </footer>
  );
};

export default Footer;
