import { Box, Flex, Heading, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";

import CircleImage from "./CircleImage";

const BenefitPreview = ({ benefit }) => {
  return (
    <Flex
      flex={1}
      py={4}
      direction={"column"}
      justify={"center"}
      minW={"250px"}
      maxW={"250px"}
      align={"center"}
    >
      <CircleImage src={benefit.icon} alt={benefit.title} />
      <Heading my={4} fontSize={"md"} align={"center"}>
        {benefit.title}
      </Heading>
      <Text fontSize={"sm"}>{benefit.ingress}</Text>
    </Flex>
  );
};

export default BenefitPreview;
