export const BenefitsData = [
  {
    icon: "./icons/dumbbell_icon.svg",
    title: "Få kunnskap",
    ingress:
      "Tilby ansatte og medlemmer verdensledende antidopingopplæring, samt få tilgang til en rekke kurs, fagartikler og ressurser om antidopingarbeid.",
  },
  {
    icon: "./icons/hand_icon.svg",
    title: "Forebygg doping",
    ingress:
      "Få tilgang til en stor forebyggende verktøykasse. Dette inkluderer blant annet informasjonsmateriell, digitalt innhold, foredrag, informasjonsstands og direkte tilgang til rådgiver hos Antidoping Norge for råd og veiledning.",
  },
  {
    icon: "./icons/drinks_icon.svg",
    title: "Ta dopingkontroller",
    ingress:
      "Som sertifisert Rent Senter får du muligheten til å gjennomføre dopingkontroller på treningssenteret. ",
  },
  {
    icon: "./icons/ribbon_icon.svg",
    title: "Vis samfunnsansvar",
    ingress:
      "Send et tydelig signal om at senteret vil fremme sunne og gode treningsvaner. Rene Sentre oppføres på Antidoping Norge sine nettsider og inngår i kampanjer ADNO gjennomfører.",
  },
];

export const StepsData = [
  {
    icon: "./icons/step1.svg",
    title: "Registrer senter",
    details: {
      icon: "./icons/step1-hover.svg",
      title: "Registrer ditt senter",
      description:
        "Gå inn på «registrer ditt senter», fyll ut skjemaet, så sender vi deg en elektronisk avtale for signering. Dette tar vanligvis 1-2 arbeidsdager.",
    },
  },
  {
    icon: "./icons/step2.svg",
    title: "Signer avtale",
    details: {
      icon: "./icons/step2-hover.svg",
      title: "Signer avtale",
      description:
        "Signer elektronisk avtale. Deretter får du tilsendt din påloggingsinformasjon for Rent Senter.",
    },
  },
  {
    icon: "./icons/step3.svg",
    title: "Bli sertifisert",
    details: {
      icon: "./icons/step3-hover.svg",
      title: "Bli sertifisert",
      description:
        "For å bli et Rent Senter må senteret gjennom en sertifiseringsprosess hvor det må legges frem nødvendig dokumentasjon.",
    },
  },
  {
    icon: "./icons/step4.svg",
    title: "Gratulerer",
    details: {
      icon: "./rs_logo.svg",
      title: "Gratulerer",
      description:
        "Senteret vil motta gyldig sertifiseringsbevis, samt få tilgang til en rekke verktøy og materiell fra Antidoping Norge.",
    },
  },
];

export const NewsData = [
  {
    image: "./images/news1.jpg",
    title: "Starter med virtuell dopingtesting på treningssenter",
  },
  {
    image: "./images/news2.jpg",
    title:
      "Arrangerte historiens første antidoping-kurs for personlige trenere",
  },
  {
    image: "./images/news3.jpg",
    title: "Kosttilskudd - dopingfelle eller nødvendig del av trening?",
  },
];
