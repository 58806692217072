import { Flex, Image } from "@chakra-ui/react";
import React from "react";

const CircleImage = ({ src, alt, size = "75px", details }) => {
  return (
    <Flex
      border="2px"
      borderColor="baseDark"
      borderRadius={"100%"}
      w={size}
      h={size}
      justify={"center"}
      align={"center"}
      my={3}
    >
      <Image src={src} objectFit="contain" alt={alt} data-details={details} />
    </Flex>
  );
};

export default CircleImage;
