// export const formatDate = (date) => {
//   const today = new Date(date);
//   const year = today.getFullYear();
//   let mes = today.getMonth() + 1;
//   mes = mes < 10 ? "0" + mes : mes;
//   let dia = today.getDate();
//   dia = dia < 10 ? "0" + dia : mes;
//   return dia + "." + mes + "." + year;
// };

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function formatDate({ date, forQuery }) {
  const tempDate = new Date(date);
  const newdate = [
    padTo2Digits(tempDate.getDate()),
    padTo2Digits(tempDate.getMonth() + 1),
    tempDate.getFullYear(),
  ];
  return forQuery ? newdate.reverse().join("-") : newdate.join("/");
}
