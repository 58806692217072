import {
  Button,
  Center,
  Container,
  Heading,
  Link as ChakraLink,
  Stack,
  Wrap,
} from "@chakra-ui/react";
import Link from "next/link";
import React from "react";

import NewsPreview from "./NewsPreview";

const NewsSection = ({ title, news, linkLable, linkHref }) => {
  return (
    <Container size="lg" py={12}>
      <Stack
        direction={["column", "column"]}
        justify={"center"}
        // align={"center"}
        spacing={6}
      >
        {title && (
          <Heading alignSelf={"flex-start"} size={"md"}>
            {title}
          </Heading>
        )}
        <Wrap
          direction={["column", "column", "row", "row"]}
          flexWrap={"wrap"}
          alignItems={"inherit"}
          justify={"space-between"}
          spacing={8}
          my={16}
        >
          {news.map((newsArticle) => (
            <NewsPreview key={newsArticle.title} news={newsArticle} />
          ))}
        </Wrap>
        {linkLable && linkHref && (
          <Center>
            <ChakraLink as={Link} href={linkHref}>
              <a>
                <Button
                  alignSelf={"flex-end"}
                  fontWeight={"bold"}
                  size={"xl"}
                  variant="ghost"
                >
                  {linkLable}
                </Button>
              </a>
            </ChakraLink>
          </Center>
        )}
      </Stack>
    </Container>
  );
};

export default NewsSection;
