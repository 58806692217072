import { Center, Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import CircleImage from "../components/CircleImage";
import SimpleCard from "../components/SimpleCard";

const TwoSidedCard = ({ step }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const flip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <>
      {!isFlipped ? (
        <Flex
          direction={"column"}
          align={"center"}
          minH={"360px"}
          maxH={"360px"}
          minW={"220px"}
          maxW={"220px"}
          bg={"baseLight"}
          cursor="pointer"
          key={step.title}
          onClick={flip}
          justify={"center"}
        >
          <CircleImage
            src={step.icon}
            alt={step.title}
            details={step.details.icon}
          />
          <Text fontWeight={"bold"} data-details={step.details.title}>
            {step.title}
          </Text>
        </Flex>
      ) : (
        <Flex
          direction={"column"}
          align={"center"}
          minH={"360px"}
          maxH={"360px"}
          minW={"220px"}
          maxW={"220px"}
          bg={"baseLight"}
          cursor="pointer"
          key={step.details.title}
          onClick={flip}
          justify={"flex-start"}
          py={4}
        >
          <Image
            boxSize="6rem"
            src={step.details.icon}
            objectFit="contain"
            alt={step.details.title}
          />
          <Center py={4}>
            <Text fontWeight={"bold"}>{step.details.title}</Text>
          </Center>
          <Center>
            <Text fontSize={"sm"} mx={4}>
              {step.details.description}
            </Text>
          </Center>
        </Flex>
      )}
    </>
  );
};

export default TwoSidedCard;

/**
 

                  frontCard={
                    <SimpleCard key={step.title}>
                      <CircleImage
                        src={step.icon}
                        alt={step.title}
                        details={step.details.icon}
                      />
                      <Text
                        fontWeight={"bold"}
                        data-details={step.details.title}
                      >
                        {step.title}
                      </Text>
                    </SimpleCard>
                  }
                  backCard={
                    <SimpleCard key={step.details.title}>>
                      <CircleImage
                        src={step.details.icon}
                        alt={step.details.title}
                      />
                      <Text fontWeight={"bold"}>{step.details.title}</Text>
                    </SimpleCard>
                  }
 */
